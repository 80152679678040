<template>
    <div id="app">
        <nav-bar v-if="user && currentPageName !== 'Login'" />
        <aside-menu v-if="user && currentPageName !== 'Login'" :site-name="sitename" :menu="menu" @menu-click="menuClick" />
        <router-view />
    </div>
</template>

<script>
    import NavBar from '@/components/SNavBar'
    import AsideMenu from '@/components/organisms/CSideMenu'
    import { mapState } from 'vuex'

    export default {
        name: 'app',
        components: { NavBar, AsideMenu },
        data() {
            return {
                sitename: 'Pocketmags',
            }
        },
        computed: {
            ...mapState(['user']),
            currentPageName() {
                return this.$route.name;
            },
            menu() {
                return [
                    '',
                    [
                        {
                            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                            label: '',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/home',
                                    label: 'Today Monitor'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    label: 'Customer Admin',
                                    to: '/customer'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/tools/script-runner',
                                    label: 'Script Runner'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Client Admin',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/manage-title',
                                    label: 'Titles'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/manage-clients',
                                    label: 'Clients'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Production',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    to: '/file-collector-settings',
                                    label: 'File Collector Settings'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/bulk-ingestor-view',
                                    label: 'Bulk Issue Ingest'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Promotions',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/promotions',
                                    label: 'Promotions'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Finance',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/finance',
                                    label: 'Finance'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Site Admin',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/manage-help-and-faqs',
                                    label: 'Help & Faqs'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/manage-title-collections',
                                    label: 'Title Collections'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/manage-pocketmags-banners',
                                    label: 'Pocketmags Banners'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/pocketmags-left-menu',
                                    label: 'Pocketmags Left Menu'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/client-uploader',
                                    label: 'Client Store'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    to: '/archive-sites',
                                    label: 'Archive Sites'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    to: '/minisites/view-sites',
                                    label: 'Mini Sites'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser'],
                                    label: 'Third Party Promotions',
                                    to: '/customer/thirdpartypromotions'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    to: '/tools/reviews',
                                    label: 'Reviews'
                                },
                            ]
                        },
                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Pocketmags Plus',
                            icon: 'view-list',
                            menu: [
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    label: 'Titles',
                                    to: '/manage-plus-titles'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    label: 'Popularity Sort',
                                    to: '/plus-titles-sort-order'
                                },
                                {
                                    authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                    label: 'Sales Report',
                                    to: '/plus-sales-report'
                                },
                                
                            ]
                        },

                        {
                            authType: ['AdminUser', 'StandardUser'],
                            label: 'Apps',
                            icon: 'view-list',
                            menu: [
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'Apple Vendors',
                                to: '/apps/manage-vendors'
                            },
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'Google Vendors',
                                to: '/apps/manage-google-vendors'
                            },
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'IAPS Errors (Apple)',
                                to: '/apps/transporter-status/errors'
                            },
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'IAPS Errors (Android)',
                                to: '/apps/transporter-status/errors/android'
                            },
                            {

                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'Title Graphics',
                                to: '/apps/title-graphics'
                            },
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                label: 'InApp Purchase Strings',
                                to: '/apps/manage-in-app-purchase-strings'
                            }
                            ]
                        },
                    {
                        authType: ['AdminUser', 'StandardUser'],
                        label: 'Misc Tools',
                        icon: 'view-list',
                        menu: [
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                to: '/monitor/dashboard',
                                label: 'Data Monitor'
                            },
                            {
                                authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic'],
                                to: '/monitor/inappstrings',
                                label: 'In App Strings'
                            },
                        ]
                    },
                    ]
                ]
            }
        },
        mounted() {
        },
        created() {
            //if (this.user) {
            //    this.$store.dispatch('getPricingTiers')
            //    this.$store.dispatch('getTitleBaseDropdown')
            //    this.$store.dispatch('getCurrencies')
            //    this.$store.dispatch('getVendors')
            //    this.$store.dispatch('getGoogleVendors')
            //    this.$store.dispatch('getTrialLengths')
            //    this.$store.dispatch('getClients')
            //}
        },
        methods: {
            menuClick(item) {
                if (item.action && item.action === '') {
                    this.$store.commit('darkModeToggle')
                }
            }
        },
        watch: {
            user: {
                handler: function (newValue, oldValue) {
                    if (newValue) {
                        this.$store.dispatch('getPricingTiers')
                        this.$store.dispatch('getTitleBaseDropdown')
                        this.$store.dispatch('getCurrencies')
                        this.$store.dispatch('getVendors')
                        this.$store.dispatch('getGoogleVendors')
                        this.$store.dispatch('getTrialLengths')
                        this.$store.dispatch('getClients')
                    }
                },
                immediate: true
            }
        }
    };
</script>

<style>
</style>

